export const palette = {
  lime: 'rgb(173, 255, 0)',
  funGreen: 'rgb(0, 104, 38)',
  white: 'rgb(255, 255, 255)',
  mineShaft: 'rgb(36, 36, 36)',
  silver: 'rgb(159, 159, 159)',
  dove: 'rgb(106, 106, 106)',
  black: 'rgb(0, 0, 0)',
  red: '#ff0000',
}

export const effect = {
  magnify: `
    @media only screen and (min-width: 920px) {
      transition: transform 0.25s ease-in-out;
      transform-origin: center center;
      transform: scale(1.001);
      &:hover {
        transform: scale(1.025);
      }  
    }
  `,
}

export const type = {
  headline1: `
    font-family: 'GT_Alpina', 'Georgia', serif;
    font-size: 2.5rem;
    @media only screen and (min-width: 1024px) {    
      font-size: 9rem;
    }
  `,
  headline2: `
    font-family: 'GT_Alpina', 'Georgia', serif;
    font-size: 3rem;
    margin-top: 1.25em;
    line-height: 1em;
    @media only screen and (min-width: 1024px) {    
      font-size: 5rem;
      line-height: 6rem;
    }
  `,
  headline3: `
    font-family: 'GT_Alpina', 'Georgia', serif;
    font-size: 2.4rem;
    @media only screen and (min-width: 1024px) {    
      font-size: 3.0rem;
    }
  `,
  pSerifLarge: `
    font-family: 'GT_Alpina', 'Georgia', serif;
    font-size: 2.4rem;
    @media only screen and (min-width: 1024px) {    
      font-size: 3.4rem;
      line-height: 3.6rem;
    }
  `,
  pSerifMedium: `
    font-family: 'GT_Alpina', 'Georgia', serif;
    font-size: 1.8rem;
    @media only screen and (min-width: 1024px) {    
      font-size: 2.4rem;
    }
  `,
  pSerifSmall: `
    font-family: 'GT_Alpina', 'Georgia', serif;
    font-size: 1.2rem;
    @media only screen and (min-width: 1024px) {    
      font-size: 1.4rem;
    }
  `,
  paragraph: `
    font-family: 'Reader', 'calibri', 'arial', sans-serif;
    font-size: 1.4rem;
    @media only screen and (min-width: 1024px) {    
      font-size: 1.8rem;
    }
  `,
  micro: `
    font-family: 'Reader', 'calibri', 'arial', sans-serif;
    font-size: 0.9rem;
    @media only screen and (min-width: 1024px) {
      font-size: 1.2rem;
    }
    letter-spacing: 2px;
    text-transform: uppercase;
  `,
  note: `
    color: black;
    font-family: 'courier', monospace;
    font-weight: bold;
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: 2px;
  `
}