import React from "react"
import styled from 'styled-components'
import { Grid, GridHelper, Margin } from '@components/atoms'
import { palette, type } from '@theme'
import { useSocialLinkQuery } from '@utils'
import { SocialLink } from '@types'
import LogoImage from '@assets/images/the-buddy-system.svg'
import StackedLogoImage from '@assets/images/the-buddy-system-stack.svg'


const Footer = ({
  address, email, telephone
}: {
  address: string, email: string, telephone: string
}): React.ReactElement => {
  const now = new Date()
  
  const socialLinks: Array<SocialLink> = useSocialLinkQuery()

  return (
    <Wrapper>
      <StyledGrid>
        
        <StyledLogoImage />

        <DataItem>
          <Label>Location</Label>
          <a href="https://www.google.com/maps/place/Marz+Community+Brewing+Company/@41.8277214,-87.6616468,17z/data=!3m1!4b1!4m5!3m4!1s0x880e2c38560f17af:0xce3b2cb9da43110!8m2!3d41.8277174!4d-87.6594528" target="_blank" >
            {address.split('\n').map( (line, i) => <span key={'x'+i}>{line}</span> )}
          </a>
        </DataItem>

        <DataItem>
          <Label>Contact</Label>
          <p> 
            <a href={`mailto:${email}`}>{email}</a>
            <br />
            <a href={`tel://${telephone}`}>{telephone}</a>
          </p>
        </DataItem>

        <DataItem>
          <Label>Let’s Socialize</Label>
          <Social>
            {socialLinks.map((social: SocialLink, i: number) => (
              <SocialNetwork href={social.link} target="_blank" key={`social_${i}`}>
                <img src={social.logo?.asset.url} alt={social.networkName} />
              </SocialNetwork>
            ))}
          </Social>
        </DataItem>
        

        <GridColumnWidth id="gridColumnWidth" />
      </StyledGrid>

      <StyledStackedLogoImage />
        
      <CopyrightGrid>
        <Copyright>&copy; {now.getFullYear()} The Buddy System</Copyright>
        <GridHelper />
      </CopyrightGrid>
      

    </Wrapper>
  )
}


const Wrapper = styled(props => <Margin {...props} />)`
  position: relative;
  z-index: 2000;
  @media only screen and (max-width: 743px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media only screen and (min-width: 744px) {
  }
  @media only screen and (min-width: 744px) {
  }
`


const StyledGrid = styled(props => <Grid {...props} />)`
  border-top: 1px solid white;
  grid-row-gap: 30px;
  padding-top: 30px;
  @media only screen and (min-width: 460px) {
    grid-template-columns: repeat(12, 1fr);
  }
  @media only screen and (min-width: 744px) {
    padding-top: 0;
    grid-row-gap: 0;    
  }
`


const DataItem = styled.div`
  grid-column-start: 1;
  grid-column-end: span 6;
  @media only screen and (max-width: 743px) {
    display: flex;
    flex-direction: column;
  }
  a {
    display: inline-block;
    color: ${palette.white};
  }
  span {
    display: block;
  }
  @media only screen and (min-width: 744px) {
    grid-column-start: auto;
    grid-column-end: span 3;
    padding: 30px 0px;
  }
  ${type.paragraph}
  @media only screen and (width: 744px) {
    font-size: 1.1rem;
  }
`


const Label = styled.div`
  ${type.headline3}
  margin-bottom: 0.5em;
`


const StyledLogoImage = styled(props => <LogoImage {...props} />)`
  display: none;
  @media only screen and (min-width: 744px) {
    position: relative;
    display: block;
    width: 80%;
    height: auto;
    grid-column-end: span 3;
    margin: 30px 0px;
    margin-top: 30px;
    path {
      fill: ${palette.white};
    }
  }
`


const StyledStackedLogoImage = styled(props => <StackedLogoImage {...props} />)`
  display: block;
  grid-column-end: span 6;
  path {
    fill: ${palette.white};
  }
  width: 100%;
  height: auto;
  @media only screen and (min-width: 744px) {
    display: none;
  }
`


const Social = styled.div `
`


const SocialNetwork = styled.a`
  position: relative;
  display: block;
  filter: invert(0);
  transition: filter 0.15s ease-in-out;
  &:hover {
    filter: invert(100%);
  }
  img {
    width: 35px;
    height: auto;
  }
  margin-right: 10px;
  @media only screen and (min-width: 1024px) {
    margin-right: 15px;    
    img {
      width: auto;
    }
  }
`


const CopyrightGrid = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5em 0 2em 0;
  @media only screen and (min-width: 744px) {
    flex-direction: row;  
    justify-content: space-between;
    margin: 0 0 5em 0;
  }
  ${type.micro}
  font-size: 0.9rem;
`


const Copyright = styled.div`
  margin-bottom: 1em;
  @media only screen and (min-width: 744px) {
    margin-bottom: 0;
  }
`


// "What the heck is this?"
// Glad you asked. There are some instances where we need the width of a grid column
// from outside the grid. 
// ex. a grid in a scroller that is wider than the window width; "12 fr" won't cut it.
// We're doing a big stupid "getElementById" to measure this.
const GridColumnWidth = styled.div`
  grid-column: 1 !important;
`

export default Footer