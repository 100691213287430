import React from "react"
import styled from 'styled-components'
import { Link } from '@components/atoms'


const LinkMask = ({slug, link}: LinkMaskProps) => {
  if(link) {
    return <AnchorWrapper href={link} />
  }
  if(slug) {
    return <LinkWrapper to={`/${slug.current}`} />
  }
  return <></>
}


const LinkMaskStyle = `
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  &:hover ~ .linkHighlight {
    text-decoration-color: rgba(255, 255, 255, 0.5);
  }
`

const AnchorWrapper = styled.a`
  ${LinkMaskStyle}
`

const LinkWrapper = styled(props => <Link {...props} />)`
  ${LinkMaskStyle}
`


interface LinkMaskProps {
  slug?: {
    current: string
  }
  link?: string
}


export default LinkMask