import React from "react"
import styled from 'styled-components'
import { type } from '@theme'


const Section = ({ title, noPadding, className, children }: SectionProps): React.ReactElement => (
  <Wrapper {...{className, noPadding, title}} >
    {title && <TitleWrapper>{title}</TitleWrapper>}
    {children}
  </Wrapper>
)


const Wrapper = styled.section<Pick<SectionProps, 'noPadding' | 'title'>>`
  padding: ${props => props.noPadding ? '0 0 90px 0' : '90px 0'};    
  @media only screen and (min-width: 1024px) {
    padding: ${props => props.noPadding ? '0 0 120px 0' : '120px 0'};    
  }
`


const TitleWrapper = styled.div`
  position: relative;
  ${type.headline2}
  border-top: 1px solid white;
  margin-bottom: 1em;
  padding-top: 1em;
`



type SectionProps = {
  title?: string
  noPadding?: boolean
  className?: string
  children: React.ReactNode
}


export default Section