import React from "react"
import styled from 'styled-components'
import { Link, Logo, Margin, MenuButton, Grid } from '@components/atoms'
import { BuddyScroller, WeatherScroller } from '@components/molecules'
import { palette  } from '@theme'
import { SocialLink } from '@types'
import { useLocation } from '@reach/router'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useSocialLinkQuery } from '@utils'


const Header = (): React.ReactElement => {
  const [navVisible, setNavVisible] = React.useState(false)
  const { pathname } = useLocation()

  const socialLinks = useSocialLinkQuery()
  
  const [scrolled, setScrolled] = React.useState(false)
  useScrollPosition(
    ({ currPos }) => {
      setScrolled(currPos.y > 10)
    }, // effect callback
    [setScrolled], // dependencies
    undefined, // position of element
    true, // use window instead of body.getBoundingClientRect
    10, // performance debounce
  )

  return (
    <>
      <FixedHeader>
        <Wrapper>
          <Margin>
            <StyledGrid {...{ scrolled }} >
            
              <Logo />
              
              <Navigation {...{ navVisible }} >
                <ul>
                  <NavItem onClick={() => setNavVisible(false)} active={pathname === '/'}><Link to="/" >Projects</Link></NavItem>
                  <NavItem onClick={() => setNavVisible(false)} active={pathname === '/about'}><Link to="/about" >About</Link></NavItem>
                  <NavItem onClick={() => setNavVisible(false)} active={pathname === '/press'}><Link to="/press" >Press</Link></NavItem>
                </ul>
              </Navigation>
              
              <MenuButtonWrapper onClick={() => setNavVisible(!navVisible)} {...{ scrolled }}>
                <MenuButton active={navVisible} />
              </MenuButtonWrapper>
              
              <SocialIcons>
                {socialLinks.map((social: SocialLink, i: number) => (
                  <li key={`social_${i}`} ><a href={social.link} >
                    <SocialIcon src={social.logo?.asset.url} alt={social.networkName} />
                  </a></li>
                ))}
                <li><RadioLink to="https://lumpenradio.com" >Lmpn Radio</RadioLink></li>
              </SocialIcons>
              
            </StyledGrid>
          </Margin>
        </Wrapper>
      </FixedHeader>
      <MarqueeHeaders>
        <MarqueeMargin>
          <BuddyScroller currentPath={pathname} />
          <WeatherScroller />
        </MarqueeMargin>
      </MarqueeHeaders>
    </>
  )
}


const FixedHeader = styled.header`
  position: fixed;
  z-index: 11001;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ff0000;
  /* background-color: rgba( 234, 60, 36, 0.85);
  backdrop-filter: blur(10px); */
`

const MarqueeHeaders = styled.header`
  position: absolute;
  z-index: 11000;
  width: 100%;
  top: 60px;
  @media only screen and (min-width: 744px) {
    top: 90px;
  }
`


const MarqueeMargin = styled(props => <Margin {...props} />)`
  @media only screen and (max-width: 743px) {
    padding-left: 0;
    padding-right: 0;
  }
`



const Wrapper = styled.div`
  width: 100%;
  @media only screen and (max-width: 743px) {
    z-index: 1101;
  }
`


const StyledGrid = styled(props => <Grid {...props} />)<{ scrolled: boolean }>`
  transition: height 0.15s ease-in-out;
  height: ${props => props.scrolled ? 50 : 60}px;
  @media only screen and (min-width: 744px) {
    height: ${props => props.scrolled ? 70 : 90}px;    
  }
  align-items: center;
`



const Navigation = styled.nav<{ navVisible?: boolean }>`
  @media only screen and (max-width: 743px) {
    font-family: 'GT_Alpina', 'Georgia', serif;
    position: fixed;
    z-index: 30000;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    padding: 20px;
    visibility: hidden;
    touch-events: none;
    opacity: 0;
    transform: scale(1.05);
    transition: 
      visibility 0.01s 0.35s, 
      opacity 0.3s 0s ease-in-out, 
      transform 0.3s 0s ease-in-out;
    will-change: opacity, transform;
    ${props => props.navVisible && `
      visibility: visible;
      touch-events: all;
      transform: scale(1);
      opacity: 1;
      transition: 
        visibility 0.01s, 
        opacity 0.3s 0.1s ease-in-out, 
        transform 0.3s 0.1s ease-in-out;
    `}
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    font-size: 4.8rem;
    ul {
      margin-top: -80px;
    }
  }
  @media only screen and (min-width: 744px) {
    grid-column-start: 6;
    grid-column-end: span 4;
    padding-top: 25px;
    ul {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      text-align: right;
    }
    font-size: 1.75rem;
  }
  @media only screen and (min-width: 1024px) {
    grid-column-start: 7;
    grid-column-end: span 3;
    font-size: 2.1rem;
    ul {
      text-left: left;      
    }
  }
`


const NavItem = styled.li<{ active: boolean, }>`
  display: block;
  a {
    color: ${palette.white};
    &:hover {
      text-decoration: underline;
    }
  }
  @media only screen and (min-width: 744px) {
    padding-left: 25px;
    &:last-of-type {
      padding-right: 0;
    }
    a {
      transition: text-decoration-color 0.15s ease-in-out;
    }
  }
  @media only screen and (min-width: 1024px) {
    padding-left: 0;
    padding-right: 45px;
  }
`


const MenuButtonWrapper = styled.div<{ scrolled: boolean }>`
  @media only screen and (min-width: 744px) {
    display: none;
  }
  position: fixed;
  z-index: 30001;
  top: 0px;
  right: 0px;
  transition: padding 0.15s ease-in-out;
  padding: ${props => props.scrolled ? 11 : 16}px 20px 24px 20px;
`


const SocialIcons = styled.ul`
  display: none;
  @media only screen and (min-width: 744px) {
    display: flex;
    flex-direction: row;    
    grid-column-end: span 3;
    align-items: center;
    justify-content: flex-end;
  }
  li {
    margin: 0; padding: 0;
    line-height: 0;
    a {
      line-height: 0;
    }
  }
`


const SocialIcon = styled.img`
  display: block;
  width: 1.5em;
  height: 1.5em;
  margin-top: 0.75em;
  margin-left: 1em;
  transition: filter 0.15s ease-in-out;
  filter: invert(100%);
  &:hover {
    filter: invert(0%);
  }
`


const RadioLink = styled(props => <Link {...props} />)`
  display: block;
  padding: 9px 15px 7px 15px;
  margin-top: 12px;
  margin-left: 2em;
  color: #ff0000;
  background-color: black;
  border-radius: 9999px;
  font-size: 11px;
  line-height: 1em !important;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.15s ease-in-out;
  white-space: nowrap;
  &:hover {
    background-color: white;
  }
  @media only screen and (max-width: 1023px) {
    display: none;
  }
`



export default Header