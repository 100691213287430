import React from "react"
import styled from 'styled-components'

const Grid = ({ children, className }: GridProps): React.ReactElement => (
  <Wrapper {...{ className }}>
    {children}
  </Wrapper>
)


{/* const Placeholder = styled.div`
  position: relative;
  grid-column-end: span 1;
  outline: 1px dashed white;
  &:after {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 0, 0, 0.1);
    content: ' ';
  }
` */}

const Wrapper = styled.div<Pick<GridProps, 'noPadding'>>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 40px;

  @media only screen and (min-width: 744px) {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 60px;
  }

  @media only screen and (min-width: 1024px) {
    grid-row-gap: 90px;
  }
`

type GridProps = {
  noPadding?: boolean
  children: React.ReactNode
  className?: string
}

export default Grid