import React from "react"
import styled from 'styled-components'

const PageWrapper = ({children}: {children: React.ReactNode}) => (
  <Wrapper>{children}</Wrapper>
)

const Wrapper = styled.main`
  margin-bottom: 50px;
  @media only screen and (min-width: 744px) {
    margin-top: 275px;
    margin-bottom: 125px;    
  }
`

export default PageWrapper