import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { palette } from '@theme'


const imgixPath = (input: string) => input.replace('cdn.sanity.io/files/zchnowat/production', 'franklyn-files.imgix.net')


const Video = ({autoPlay, muted, loop, hideIfMobile, hideIfDesktop, playsInline, src}: VideoProps): React.ReactElement => {
  if(!src) return <></>

  const { ref, inView } = useInView({
    threshold: .15,
  })
  
  const [visible, setVisible] = React.useState(false)
  React.useEffect(() => {
    if(!visible && inView) setVisible(true)
  }, [inView])

  return (
    <VideoTag {...{ autoPlay, muted, loop, hideIfMobile, hideIfDesktop, playsInline, visible, ref }}>
      <source src={imgixPath(src)} />
    </VideoTag>
  )
}



const VideoTag = styled.video.attrs(
  ({ visible }: {
    visible: boolean, 
  }) => {
  return { style: {
    opacity: visible ? 1 : 0,
    transform: visible ? 'translateY(0px)' : 'translateY(150px)',
  } }}
)<{ 
  rotation?: number,
  hideIfDesktop?: boolean,
  hideIfMobile?: boolean,
  visible: boolean,
}>`
  background-color: ${palette.mineShaft};
  width: 100%;
  max-width: 100%;
  border-radius: 15px;
  @media only screen and (max-width: 743px) {
    ${props => props.hideIfMobile && `display: none;`}
  } 
  @media only screen and (min-width: 744px) {
    ${props => props.hideIfDesktop && `display: none;`}
  }
  transition: opacity 0.5s ease-in-out;
  @media only screen and (min-width: 744px) {
    transition: opacity 0.5s ease-in-out, transform 0.25s ease-out;
  }
  @media only screen and (max-width: 743px) {
    transform: none !important;
  }
`


type VideoProps = {
  autoPlay?: boolean
  muted?: boolean
  loop?: boolean
  hideIfMobile?: boolean
  hideIfDesktop?: boolean
  playsInline?: boolean
  src: string
}


export default Video