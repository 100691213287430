import React from "react"
import styled from 'styled-components'


const Margin = ({
  children,
  className,
  noGutter = false,
  id = undefined,
}: MarginProps): React.ReactElement => (
  <Wrapper className={className} {...{ noGutter, id }} >{children}</Wrapper>
)


const Wrapper = styled.div<{ noGutter: boolean }>`
  padding-left: ${props => props.noGutter ? 0 : 20}px;
  padding-right: ${props => props.noGutter ? 0 : 20}px;
  box-sizing: content-box;
  margin: 0 auto;

  @media only screen and (min-width: 744px) {
    padding-left: ${props => props.noGutter ? 0 : 30}px;
    padding-right: ${props => props.noGutter ? 0 : 30}px;
  }

  @media only screen and (min-width: 1440px) {
    max-width: 1380px;
  }
`

export type MarginProps = {
  children?: React.ReactNode
  className?: string
  noGutter?: boolean
  id?: string
}


export default Margin
