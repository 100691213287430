import React from "react"
import { Picture, LinkMask } from '@components/atoms'
import { GridItemWrapper, ChildrenWrapper } from './GridItem'
import { GridItemImageProps } from '@types'


const GridItemImage = ({
  link,
  post,
  layout: {
    fullBleed,
    layout,
  },
  children,
  description,
  gridItemTypeSwitch,
  className,
}: { className?: string } & GridItemImageProps): React.ReactElement => {
  if( !gridItemTypeSwitch.gridItemImage ) return <></>
  const {
    gridItemImage: {
      image,
      mobileImage,
    },
  } = gridItemTypeSwitch

  return (
    <GridItemWrapper {...{ fullBleed, className }} {...layout} >
      <LinkMask {...{link}} slug={post?.slug} />
      <Picture 
        src={image?.asset.url}
        dimensions={image?.asset.metadata.dimensions}
        mobileSrc={mobileImage?.asset.url} 
        alt={description}
      />
      {children && (
        <ChildrenWrapper>
          {children}
        </ChildrenWrapper>
      )}
    </GridItemWrapper>
  )
}



export default GridItemImage