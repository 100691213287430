// deprecated, just keeping around because why not

import React from "react"
import styled from 'styled-components'
import { Grid, Picture, LinkMask, } from '@components/atoms'
import { PostListProps } from '@types'
import { type } from '@theme'



const HomeProjectLine = ({ slug, shareImage, description, title }: PostListProps) => (
  <Project>
    <LinkMask slug={slug}  />
    <Picture 
      src={shareImage?.asset.url}
      alt={description}
    />
    <ItemGrid>
      <BuddyTitle>{title}</BuddyTitle>
      <BuddyDescription>{description}</BuddyDescription>
    </ItemGrid>
  </Project>
)



const Project = styled.div`
  position: relative;
  grid-column-end: span 6;
`


const ItemGrid = styled(props => <Grid {...props} />)`
  grid-row-gap: 0px;
  padding: 20px 0 10px 0;
  @media only screen and (min-width: 744px) {
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 0px;
    padding: 35px 0 30px 0;
  }
  &:before {
    content: ' ';
    grid-column-start: 1;
    grid-column-end: span 1;
    width: calc(100% - 10px);
    height: 1px;
    background: white;
    margin-top: 23px;
  }
`


const BuddyTitle = styled.h1`
  grid-column-start: 2;
  grid-column-end: span 5;
  ${type.headline3}
`


const BuddyDescription = styled.p`
  grid-column-start: 2;
  grid-column-end: span 5;
  ${type.paragraph}
  margin-top: 1em;
`



export default HomeProjectLine