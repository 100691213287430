// usage:   {process.env.GATSBY_SANITY_WATCH_MODE && (<GridHelper />)}

import React from 'react'
import styled from 'styled-components'
import { Margin, Grid } from '@components/atoms'
import { range } from 'lodash'



const GridHelper = () => {
  const [visible, setVisible] = React.useState(false)
  if(process.env.NODE_ENV !== 'development') return <></>
  return (
    <>
      <GridHelperButton onClick={() => setVisible(!visible)}>Grid Helper</GridHelperButton>
      <StyledMargin {...{ visible }} >
        <StyledGrid>
          {range(0, 12).map((_, i) => (
            <GridColumn key={`view_column_${i}`} hideMobile={(i > 5)} >{i + 1}</GridColumn>
          ))}
        </StyledGrid>
      </StyledMargin>
    </>
  )
}





const StyledMargin = styled(props => <Margin {...props} />)<{ visible: boolean }>`
  display: ${props => props.visible ? 'block' : 'none'};
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  @media only screen and (max-width: 1379px) {
    box-sizing: border-box !important;    
  }
  pointer-events: none;
`

const StyledGrid = styled(props => <Grid {...props} />)`
  padding: 0;
`

const GridColumn = styled.div<{ hideMobile: boolean }>`
  @media only screen and (max-width: 743px) {
    display: ${props => props.hideMobile ? 'none' : 'block'};
  }
  height: 100vh;
  grid-column: span 1;
  background-color: rgba(255, 255, 255, 0.05);
  text-align: center;
  font-size: 9px;
  color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: center; 
`

const GridHelperButton = styled.button`
  color: white;
  opacity: 0.3;
  &:hover {
    opacity: 1;
  }
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: right;
  @media only screen and (min-width: 744px) {
    text-align: center;
  }
`


export default GridHelper
