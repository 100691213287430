import React from "react"
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Footer, Header } from '@components/organisms'
import { SiteSettings } from '@types'

import "../assets/stylesheets/reset.min.css"
import "../assets/stylesheets/main.scss"


const Layout = ({ className, children }: LayoutProps): React.ReactNode => {
  const [loaded, setLoaded] = React.useState(false)
  React.useEffect(() => {
    setTimeout(() => setLoaded(true), 5)
  }, [])
  
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      sanitySiteSettings {
        ...siteSettings
      }
    }
  `)

  const { sanitySiteSettings: { title, description, keywords, address, telephone, email, shortcutIcon, touchIcon, shareImage } } = data
  
  const meta = [
    { property: "og:type", content: "website" },
    { property: "og:url", content: `${process.env.GATSBY_SITE_BASE_URL}` },
    { charSet: 'UTF-8' },
    { name: "viewport", content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" },
    { httpEquiv: "X-UA-Compatible", content: "ie=edge" },
    { name: "apple-mobile-web-app-capable", content: "yes" },

    { property: "og:title", content: title },
    { name: "twitter:title", content: title },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: "franklyn_design" },
    
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },

    { name: "keywords", content: keywords },
    
    { property: "og:image", content: `${shareImage?.asset.url}?w=640&h=640&fm=jpg&q=75` },
    { name: "twitter:image", content: shareImage?.asset.url },
    
    { name: "theme-color", content: '#000000', media: '(prefers-color-scheme: dark)' },
    { name: "theme-color", content: '#ff0000', media: '(prefers-color-scheme: light)' },
  ]

  return (
    <>
      <Helmet {...{ meta, title }} >
        <link rel="apple-touch-icon" href={`${touchIcon?.asset.url}?v=0.1`} />
        <link rel="icon" type="image/png" href={shortcutIcon?.asset.url} sizes="32x32" />
        <link rel="preload" as="font" href="/fonts/GT_Alpina/GT-Alpina-Regular-Trial.woff2" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/fonts/Reader/Reader-Regular.woff2" type="font/woff2" crossOrigin="anonymous" />
      </Helmet>
      <Top id="top" />
      <Header />
      <Wrapper className={loaded ? 'loaded' : 'loading'} {...{ className }}>
        {children}
      </Wrapper>
      <Footer {...{ address, telephone, email }} />
    </>
  )
}


const Wrapper = styled.div`
  overflow: hidden;
  min-height: 100vh;
  @media only screen and (max-width: 743px) {
    margin-top: 190px;
  }
`


const Top = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
`



interface LayoutProps extends SiteSettings {
  subtitle?: string
  preview?: boolean
  className?: string
  children: React.ReactNode
}


export default Layout
