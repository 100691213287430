import React from "react"
import styled from 'styled-components'
import { Picture, LinkMask, } from '@components/atoms'
import { PostListProps } from '@types'
import { type } from '@theme'



const HomeProjectCenter = ({ slug, shareImage, description, title, tagLine }: PostListProps) => (
  <Project>
    <LinkMask slug={slug}  />
    <Picture 
      src={shareImage?.asset.url}
      alt={description}
    />
    {tagLine && (
      <BuddyTag>{tagLine}</BuddyTag>
    )}
    <BuddyTitle className="linkHighlight" >{title}</BuddyTitle>
    <BuddyDescription>{description}</BuddyDescription>
  </Project>
)



const Project = styled.div`
  position: relative;
  grid-column-end: span 6;
  text-align: center;
  @media only screen and (max-width: 743px) {
    margin-bottom: 2em;
  }
`


const BuddyTag = styled.div`
  margin: 2em 0 1em 0;
  ${type.note}
`



const BuddyTitle = styled.h1`
  ${type.headline2}
  line-height: 1em;
  margin: 0 10%;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-decoration-color: rgba(255, 255, 255, 0);
  text-underline-offset: 0.1em;
  transition: text-decoration-color 0.35s ease-in-out;
`


const BuddyDescription = styled.p`
  ${type.paragraph}
  margin: 1em 10% 0 10%;
`



export default HomeProjectCenter