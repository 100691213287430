import React from "react"
import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'
import { type } from '@theme'


const BlockSerializer = ({children, node, ...props}): JSX.Element => {
  switch(node.style) {
    case 'headline1':
      return <Text type={type.headline1} >{children}</Text>
    case 'headline2':
      return <Text type={type.headline2} >{children}</Text>
    case 'headline2Center':
      return <Text type={type.headline2} center >{children}</Text>
    case 'headline3':
      return <Text type={type.headline3} >{children}</Text>
    case 'pSerifLarge':
      return <TextP type={type.pSerifLarge} >{children}</TextP>
    case 'pSerifLargeCenter':
      return <TextP type={type.pSerifLarge} center >{children}</TextP>
    case 'pSerifMedium':
      return <TextP type={type.pSerifMedium} >{children}</TextP>
    case 'pSerifSmall':
      return <TextP type={type.pSerifSmall} >{children}</TextP>
    case 'microCaps':
      return <Micro>{children}</Micro>
    case 'p':
    default:
      return <Paragraph>{children}</Paragraph>
  }
}

const BlockRenderer = ({children}) => {
  return (
    <StyledBlockContent
      blocks={children}
      serializers={{
        types: {
          block: props => (<BlockSerializer {...props} />),
        },
        marks: {
        },
      }}
    />
  )
}



const StyledBlockContent = styled(props => <BlockContent {...props} />)`
`


const Paragraph = styled.p`
  ${type.paragraph}
`

const TextP = styled.p<{
  type: string,
  center?: boolean
}>`
  ${props => props.type}
  ${props => props.center && `text-align: center;`}
  margin-bottom: 1em;
  &:last-child {
    margin-bottom: 0;
  }
`


const Text = styled.h1<{
  type: string,
  center?: boolean
}>`
  ${props => props.type}
  ${props => props.center && `text-align: center;`}
  margin-bottom: 1em;
  &:last-child {
    margin-bottom: 0;
  }
`

const Micro = styled.div`
  ${type.micro}
`






export default BlockRenderer