import React from "react"
import styled from 'styled-components'
import { GridItem } from '@components/molecules'
import { GridItemWrapper } from './GridItem'
import { type } from '@theme'
import { useWindowSize} from '@utils'
import { GridItemStripProps} from '@types'


const GridItemFocusStrip = ({ 
  insideGrid,
  stripItems,
  className,
  children,
}: GridItemStripProps): React.ReactElement => {
  const { width } = useWindowSize()

  const [gridColumnWidth, setGridColumnWidth] = React.useState<number>(1)
  React.useEffect(() => {
    const gridSizer = document.getElementById('gridColumnWidth')
    if(!gridSizer) return
    setTimeout(() => setGridColumnWidth(gridSizer.offsetWidth), 50)
  }, [width])

  const [focusIndex, setFocusIndex] = React.useState<number>(0)
  const shift = (direction: 'left' | 'right') => {
    if( direction === 'left' && focusIndex > 0 ) {
      setFocusIndex(focusIndex - 1)
    } else if( direction === 'right' && stripItems && focusIndex < stripItems.length - 1 ) {
      setFocusIndex(focusIndex + 1)
    }
  }
  
  if(!stripItems) return <></>
  
  return (
    <GridItemWrapper>
      <Wrapper {...{ insideGrid, className }}>
        <ScrollerArrow 
          active={focusIndex >= 1} 
          onClick={() => shift('left')}
          left
        />
        <ScrollerArrow 
          active={focusIndex < (stripItems.length - 1)}
          onClick={() => shift('right')}
        />
        <Canvas>
          {stripItems.map((item, i) => (
            <StyledGridItem 
              {...item} 
              _type="gridItem"
              key={i} 
              index={i}
              columns={item.layout?.layout?.columns ?? 6} 
              mobileColumns={item.layout?.layout?.mobileColumns ?? 5}
              {...{ gridColumnWidth, focusIndex }}
            />
          ))}
          {children}
          {!children && stripItems?.map((item, i) => (
            <Placeholder key={i + 9999} >
              <StyledGridItem 
                {...item} 
                _type="gridItem"
                key={i} 
                columns={item.layout?.layout?.columns ?? 6} 
                mobileColumns={item.layout?.layout?.mobileColumns ?? 5}
                {...{ gridColumnWidth }}
                placeholder
              />
            </Placeholder>
          ))}
        </Canvas>
        <Captions {...{ gridColumnWidth }}>
          <Counter>Image {focusIndex+1} of {stripItems.length}</Counter>
          {stripItems.map((item, i) => (
            <Caption active={focusIndex === i} key={i + 999} >{item.description}</Caption>
          ))}
        </Captions>
      </Wrapper>
    </GridItemWrapper>
  )
}


const Captions = styled.div<{ gridColumnWidth: number }>`
  @media only screen and (max-width: 743px) {
    min-height: 50px; 
    padding: 0 20px;
    margin-top: 1.5em;
  }
  @media only screen and (min-width: 744px) {
    position: absolute;
    top: 0;
    left: 30px;
    height: 100%;
    width: ${props => props.gridColumnWidth * 2 + 30}px;
    min-height: 0;
  }

  @media only screen and (min-width: 1440px) {
    left: calc((100vw - 1380px) / 2);
  }


  ${type.pSerifSmall}
`

const Counter = styled.div`
  text-transform: uppercase;
`

const Caption = styled.div<{ active: boolean }>`
  position: absolute;
  width: 100%;
  opacity: ${props => props.active ? 1 : 0};
  transition: opacity 0.15s ease-in-out;
`


/* The trickiest part of this whole mess is getting the canvas to be the correct height.
  The items are absolute positioned, so the canvas "wants" to have a height of 0.
  Programmatically setting the height is a fucking mess.
  So we're just jamming in some 1px wide placeholders; the tallest item will do the work.
*/
const Placeholder = styled.div`
  overflow-x: hidden;
  display: inline-block;
  width: 1px;
  opacity: 0;
`


const Canvas = styled.div`
  position: relative;
  width: 100vw;
  overflow-x: hidden;
`


const StyledGridItem = styled(props => <GridItem {...props} />)<{ 
  gridColumnWidth: number, 
  columns: number, 
  mobileColumns: number,
  placeholder?: boolean,
  focusIndex?: number,
  index: number,
}>`
  z-index: ${props => 100 - props.index};
  width: ${props => ((props.mobileColumns ?? 6) * props.gridColumnWidth) + ((props.mobileColumns - 1) * 20)}px;
  @media only screen and (min-width: 744px) {
    width: ${props => ((props.columns ?? 12) * props.gridColumnWidth) + ((props.columns - 1) * 30)}px;
  }
  @media only screen and (min-width: 1024px) {
    width: ${props => ((props.columns ?? 12) * props.gridColumnWidth) + ((props.columns - 1) * 30)}px;
  }

  ${props => !props.placeholder && `
    position: absolute !important;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    left: calc(50% - ${((props.mobileColumns * props.gridColumnWidth) + ((props.mobileColumns - 1) * 20)) / 2}px);

    transition: transform 0.45s ease-out;
    transform-origin: center center;
    
    /*
    half a screen away:                 -50vw
    width of item:                      ((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 30)) * (Math.abs(props.focusIndex - props.index)
    one deviation from index:           * (Math.abs(props.focusIndex - props.index))
    halved to bring to edge of screen:  / 2 
    margin for visible edge:            - (Math.abs(props.focusIndex - props.index) === 1 ? 60 : 0)
    */
    
    transform: translateX(
      ${props.focusIndex === props.index ? 0
        : props.index < props.focusIndex ? `calc(-1 * ${((props.mobileColumns * props.gridColumnWidth) + ((props.mobileColumns - 1) * 20)) * (Math.abs(props.focusIndex - props.index)) + 20 }px)`
        : `calc(${((props.mobileColumns * props.gridColumnWidth) + ((props.mobileColumns - 1) * 20)) * (Math.abs(props.focusIndex - props.index)) + 20 }px)`
      }
    ); /*  scale(${props.focusIndex === props.index ? 1 : 0.8}) */
    
    @media only screen and (min-width: 744px) {
      left: calc(50% - ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 30)) / 2}px);
      transform: translateX(
        ${props.focusIndex === props.index ? 0
          : props.index < props.focusIndex ? `calc(-50vw - ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 30)) * (Math.abs(props.focusIndex - props.index)) / 2}px)`
          : `calc(${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 30)) * (Math.abs(props.focusIndex - props.index)) + 30 }px)`
        }
      );
    }
    
    @media only screen and (min-width: 1024px) {
      left: calc(50% - ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 30)) / 2}px);
      transform: translateX(
        ${props.focusIndex === props.index ? 0
          : props.index < props.focusIndex ? `calc(-50vw - ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 30)) * (Math.abs(props.focusIndex - props.index)) / 2}px)`
          : `calc(${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 30)) * (Math.abs(props.focusIndex - props.index)) + 30 }px)`
        }
      );
    }
    
    @media only screen and (min-width: 1200px) {
      transform: translateX(
        ${props.focusIndex === props.index ? 0
          : props.index < props.focusIndex ? `calc(-50vw - ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 30)) * (Math.abs(props.focusIndex - props.index)) / 2}px)`
          : `calc(${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 30)) * (Math.abs(props.focusIndex - props.index)) + 30 }px)`
        }
      );
    }

  `}
  
  /* 
    override "fade up" animation, it messes up the scroller 
    override opacity, it doesn't trigger until more than inside margin
  */
  img, video {
    transform: none !important;
    opacity: 1 !important;
  }
      
`


const Wrapper = styled.div<{ insideGrid?: boolean }>`
  position: relative;
  overflow: hidden;
  width: 100vw;
  margin-left: -20px;
  @media only screen and (min-width: 744px) {
    margin-left: -30px;
  }
  @media only screen and (min-width: 1440px) {
    margin-left: calc((100vw - 1380px) / 2 * -1);
  }
`


const ScrollerArrow = styled.div<{ left?: boolean, active: boolean }>`
  position: absolute;
  z-index: 101;
  ${props => props.left ? `
    left: 0px;
    transform: rotateZ(180deg);
  ` : `
    right: 0px;
  `}
  top: 0;
  bottom: 0;
  width: 30px;
  padding: 0 20px;
  @media only screen and (min-width: 744px) {
    padding: 0 30px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 0 45px;
  }
  @media only screen and (min-width: 1200px) {
    padding: 0 60px;
  }
  background-image:url('/images/scrollerArrow.svg');
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: opacity 0.25s ease-in-out;
  opacity: ${props => props.active ? 1 : 0};
  cursor: ${props => props.active ? 'pointer' : 'inherit'};
  transition: filter 0.15s ease-in-out;
  filter: invert(1);
  &:hover {
    filter: invert(0);
  }
`



export default GridItemFocusStrip