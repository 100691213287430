import React from "react"
import styled from 'styled-components'
import { useLocation } from '@reach/router'
import { Link } from '@components/atoms'
import LogoImage from '@assets/images/the-buddy-system.svg'



const Logo = (): React.ReactElement => {  
  const { pathname } = useLocation()

  const scrollToTopMaybe = (e: MouseEvent) => {
    if(pathname === '/') {
      e.preventDefault()
      window.scrollTo({behavior: 'smooth', left: 0, top: 0})
    }
  }

  return (
    <Wrapper>
      <Link to="/" onClick={(e: MouseEvent) => scrollToTopMaybe(e)} >
        <StyledLogoImageWrapper>
          <LogoImage />
        </StyledLogoImageWrapper>
      </Link>
    </Wrapper>
  )
}



const Wrapper = styled.div`
  grid-column-end: span 4;
  @media only screen and (min-width: 744px) {
    grid-column-end: span 5;
  }
  @media only screen and (min-width: 1024px) {
    grid-column-end: span 3;
  }
`

const StyledLogoImageWrapper = styled.div`
  object-fit: contain;
  height: auto;
  width: 215px;
  margin-top: 10px;
  @media only screen and (min-width: 744px) {
    width: 100%;  
    margin-top: 20px;
  }
  svg {
    max-width: 100%;
  }
`

export default Logo