import React from "react"
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from '@components/atoms'
import { useWindowSize } from '@utils'
import { type } from '@theme'


const BuddyScroller = ({currentPath}: {currentPath: string}) => {  
  const { allSanityPost: { nodes } } = useStaticQuery(graphql`
    query BuddyListQuery {
      allSanityPost {
        nodes {
          ...buddyList          
        }
      }
    }
  `)
  if(!nodes) return <></>
  
  const { width } = useWindowSize()
  const [scale, setScale] = React.useState(1)
  React.useEffect(() => {
    const buddyCanvas = 
      (width < 744) ? (width - 40)
      : (width < 1024) ? (width - 90) / 2
      : (width < 1200) ? (width - 120) / 2
      : (width < 1500) ? (width - 150) / 2
      : 675
    setScale(buddyCanvas / 340)
  }, [width, setScale])
  return (
    <Wrapper>
      <Marquee>
        <BuddySegment {...{ scale, currentPath, nodes }} />
        <BuddySegment {...{ scale, currentPath, nodes }} />
      </Marquee>
    </Wrapper>
  )
}


export interface Coordinates {
  w: number
  x: number
  y: number
}

interface BuddySegmentProps {
  scale: number
  currentPath: string
  nodes: Array<{
    slug: {
      current: string
    }
    coordinates?: Coordinates
    title: string
    logo?: {
      asset: {
        url: string
      }
    }
  }>
}

const BuddySegment = ({ scale, currentPath, nodes }: BuddySegmentProps) => (
  <Buddies>
    {nodes.filter(node => node.logo && node.coordinates).map((node, i) => (
      <StyledLink to={`/${node.slug.current}`} key={i}
        $desktop={node.coordinates!}
        $scale={scale}
      >
        <Buddy 
          src={node.logo?.asset.url}
          active={currentPath === node.slug.current}
        />
        <BuddyLabel>{node.title}</BuddyLabel>
      </StyledLink>
    ))}
  </Buddies>
)

/*
  1. Three banners
  2. slide 'em
  3. move 'em
  4. pause 'em
*/



const Wrapper = styled.div`
  overflow: hidden;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  
  width: 100%;
  position: relative;
  height: 115px;
  border-top: 2px solid white;
  @media (hover: none) and (pointer: coarse) {
    height: 90px;
  }
  @media only screen and (max-width: 743px) {
    height: 90px;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(-100%);
    }
     100% {
      transform: translateX(0);
    }
  }
`


const Buddies = styled.div`
  position: relative;
  display: inline-block;
  height: 115px;
  width: 1400px;
  @media (hover: none) and (pointer: coarse) {
    margin-top: 5px;
    height: 90px;
    width: 1000px;
  }
  @media only screen and (max-width: 743px) {
    margin-top: 5px;
    height: 90px;
    width: 1000px;
  }
  overflow: hidden;
`


const Marquee = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  will-change: transform;
  ${Buddies} {
    animation: marquee linear infinite;
    animation-duration: 15s;
  }
  &:hover ${Buddies} {
    animation-play-state: paused;
  }
`


const StyledLink = styled(props => <Link {...props} />)<{
  $desktop: Coordinates,
  $scale: number,
}>`
  position: absolute;
  display: block;
  min-height: 1px;
  
  left: ${props => props.$desktop.x}px;
  top:  ${props => props.$desktop.y}px;
  width:${props => props.$desktop.w}px;
  @media (hover: none) and (pointer: coarse) {
    left: ${props => props.$desktop.x * .715}px;
    top:  ${props => props.$desktop.y * .7}px;
    width:${props => props.$desktop.w * .7}px;
  }
  @media only screen and (max-width: 743px) {
    left: ${props => props.$desktop.x * .715}px;
    top:  ${props => props.$desktop.y * .7}px;
    width:${props => props.$desktop.w * .7}px;
  }
  div {
    display: none;
  }
`


const Buddy = styled.img<{ active: boolean }>`
  width: 100%;
  height: auto;
  filter: invert(${props => props.active ? 100 : 0}%);
  &:hover {
    filter: invert(100%);    
  }
  transition: filter 0.15s ease-in-out;
`


const BuddyLabel = styled.div`
  ${type.paragraph}
  line-height: 1.6em;
`


/*
    top 0.5s ease-in-out, 
    left 0.5s ease-in-out,
    width 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275),
*/




export default BuddyScroller