import React from "react"
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { Marquee } from '@components/molecules'
import { OpenWeatherData } from '@types'


const WeatherScroller = () => {
  const data: OpenWeatherData = useStaticQuery(graphql`
    query WeatherQuery {
      openWeather {
        weatherData {
          name
          timezone
          main {
            humidity
            temp
            pressure
          }
          coord {
            lat
            lon
          }
          weather {
            description
          }
        }
      }
    }
  `)

  const { openWeather: { weatherData } } = data

  return (
    <StyledMarquee direction="left" speed={30}>
      {weatherData.map((location, i: number) => (
        <ul key={`weather_location_${i}`} >
          <li>{location.name}</li>
          <li>Latitude: {location.coord.lat}</li>
          <li>Longitude: {location.coord.lon}</li>
          <li>Temperature: {location.main.temp}º F</li>
          <li>Humidity: {location.main.humidity}%</li>
          {location.weather.map((d, ii) => (
            <li className="capitalize" key={ii} >{d.description}</li>
          ))}
        </ul>
      ))}
    </StyledMarquee>
  )
}


const StyledMarquee = styled(props => <Marquee {...props} />)`
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  padding: 7px 0px 4px 0px;
  color: white;
  
  @media (hover: none) and (pointer: coarse) {
    font-size: 0.8em;
  }
  
  ul {
    display: inline-flex;
    li {
      display: inline-flex;
      &:after {
        content: '•';
        padding: 0 8px;
      }
      &.capitalize {
        text-transform: capitalize;
      }
    }
  }
`


export default WeatherScroller