import React from "react"
import { LinkMask, Video } from '@components/atoms'
import { GridItemWrapper, ChildrenWrapper } from './GridItem'
import { GridItemVideoProps } from '@types'


const GridItemVideo = ({
  link,
  post,
  layout: {
    fullBleed,
    layout,
  },
  children,
  gridItemTypeSwitch,
  className,
}: GridItemVideoProps): React.ReactElement => {
  if( !gridItemTypeSwitch.gridItemVideo ) return <></>
  const {
    gridItemVideo: {
      video,
      mobileVideo,
    },
  } = gridItemTypeSwitch

  return (
    <GridItemWrapper {...{ fullBleed, className }} {...layout} >
      <LinkMask {...{link}} slug={post?.slug}  />
      {mobileVideo && (
        <Video autoPlay muted loop hideIfDesktop playsInline src={mobileVideo.asset.url} />
      )}
      <Video autoPlay muted loop hideIfMobile={!!mobileVideo} playsInline src={video?.asset.url} />
      {children && (
        <ChildrenWrapper>
          {children}
        </ChildrenWrapper>
      )}
    </GridItemWrapper>
  )
}





export default GridItemVideo