import React from "react"
import styled from 'styled-components'
import { palette, type } from '@theme'


const Newsletter = (): React.ReactElement => {
  const [email, setEmail] = React.useState('')
  const [valid, setValid] = React.useState(false)
  const newsLetterForm = React.useRef<any>()
  const blank = ''
  React.useEffect(() => setValid(/\S+@\S+\.\S+/.test(email)), [email])

  const checkSubmit = (event: any) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      if(!valid) return
      newsLetterForm?.current?.submit()
    }
  }
  return (
    <Wrapper
      action="https://lumpenradio.us10.list-manage.com/subscribe/post?u=e9b74940ea8fe328ad492732d&amp;id=1e0f524d1e" 
      method="post"
      target="_blank"
      ref={newsLetterForm}
    >
      <Title>Wanna be buddies?<br />Let us into your inbox.</Title>
      <NewsletterInput 
        type="email" 
        name="EMAIL" 
        required={true}
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
        placeholder="Your Email Address"
        onKeyUp={(e) => checkSubmit(e)}
      />
      <input type="hidden" name="group[157356]" value="2" />
      <HiddenInput type="text" name="b_e9b74940ea8fe328ad492732d_1e0f524d1e" tabIndex={-1} value={blank} aria-hidden={true} readOnly />
      <Disclaimer>We promise to only give you the juiciest bits.</Disclaimer>
    </Wrapper>
  )
}



const Wrapper = styled.form`
  grid-column-end: span 6;
  position: relative;
  padding: 4rem 0;
  margin-bottom: 2em;
  @media only screen and (min-width: 744px) {
    margin-bottom: 0;
    padding: 8rem 0;
    grid-column-end: span 12;
  }
  border-bottom: 2px solid ${palette.white};
  border-top: 2px solid ${palette.white};
  text-align: center;
`


const Title = styled.h2`
  ${type.headline1}
  margin-top: 0;
  line-height: 1em;
  font-size: 3rem;
  @media only screen and (min-width: 744px) {
    font-size: 6rem;
  }
`


const Disclaimer = styled.div`
  ${type.note}
  @media only screen and (max-width: 743px) {
    max-width: 250px;
    margin: auto;
  }
`


const NewsletterInput = styled.input`
  width: 100%;
  border: 0;
  outline: 0;
  padding: 14px 20px 12px 20px;
  margin: 2em 0 3em 0;
  border-radius: 10px;
  background: white;
  font-size: 1.2rem;
  @media only screen and (min-width: 744px) {
    max-width: 450px;
    padding: 22px 3px 18px 3px;
    font-size: 1.8rem;
  }
  font-family: 'Reader', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  text-align: center;
  color: black;
  ::placeholder {
    color: rgb(255, 0, 0);
  }
`

const HiddenInput = styled.input`
  position: absolute;
  z-index: -9999px;
`


export default Newsletter