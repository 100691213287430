import React from "react"
import styled from 'styled-components'
import { GridItem } from '@components/molecules'
import { GridItemWrapper } from './GridItem'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useWindowSize } from '@utils'
import { GridItemStripProps} from '@types'


const GridItemScrollStrip = ({ 
  stackMobile,
  insideGrid,
  stripItems,
  children,
  className,
}: { stackMobile?: boolean, insideGrid?: boolean, children?: React.ReactNode } & GridItemStripProps): React.ReactElement => {
  const { width } = useWindowSize()

  const scroller = React.useRef<HTMLDivElement>(null)
  const strip = React.useRef<HTMLDivElement>(null)
  const [stripPosition, setStripPosition] = React.useState({x: 0, y: 0})
  useScrollPosition(
    ({ currPos }) => setStripPosition(currPos), // effect callback
    [setStripPosition], // dependencies
    strip, // position of element
    false, // use window instead of body.getBoundingClientRect
    10, // performance debounce
    scroller, // element wrapper (actual scroll)
  )

  /* We are constricting the user options for setting up the grid.
   * Normally they are able to set the width and starting column.
   * They can still set the width, but we are just putting them in an unbroken row without gaps now.
   */
  const [gridColumnWidth, setGridColumnWidth] = React.useState<number>()
  React.useEffect(() => {
    setTimeout(() => setGridColumnWidth(document.getElementById('gridColumnWidth')?.offsetWidth), 50)
  }, [width])

  const scroll = (direction: 'left' | 'right') => {
    const w = window.innerWidth
    const x = direction === 'left' ? stripPosition.x - w : stripPosition.x + w
    scroller.current?.scrollTo({behavior: 'smooth', left: x, top: 0})
  }
  
  const disableScroll = stripItems 
    ? (stripItems?.reduce((n, item) => n + (item.layout?.layout?.columns || 12), 0) <= 12)
    : React.Children.toArray(children).length <= 2
      // potential bug: This isn't really managing the width of the children, just the count.
  
  return (
    <GridItemWrapper>
      <Wrapper {...{ stackMobile, insideGrid, className }}>
        {!disableScroll && (<>
          <ScrollerArrow 
            active={stripPosition.x > 0} 
            onClick={() => scroll('left')}
            left
            stackMobile
          />
          <ScrollerArrow 
            active={typeof window !== 'undefined' && stripPosition.x < (strip.current?.scrollWidth! - window.innerWidth)}
            onClick={() => scroll('right')}
            stackMobile
          />          
        </>)}
        <Scroller {...{ stackMobile, disableScroll }} ref={scroller} >
          <Strip {...{ gridColumnWidth, stackMobile, }} ref={strip}  >
            {children && React.Children.map(children, (child) => React.cloneElement(child as React.ReactElement, { gridColumnWidth }))}
            {stripItems && stripItems.map((item, i) => (
              <ScrollStripGridItem 
                {...item} 
                _type="gridItem"
                key={i} 
                columns={item.layout?.layout?.columns ?? 12} 
                mobileColumns={item.layout?.layout?.mobileColumns ?? 6}
                {...{ gridColumnWidth }}
              />
            ))}
          </Strip>
        </Scroller>
      </Wrapper>
    </GridItemWrapper>
  )
}




const Wrapper = styled.div<{ stackMobile?: boolean, insideGrid?: boolean }>`
  position: relative;
  overflow: hidden;
  ${props => !props.stackMobile && `
    width: 100vw;
  `}  
  @media only screen and (min-width: 744px) {
    width: 100vw;
    margin-left: -30px;
  }

  ${props => props.insideGrid && `
    margin-left: -20px;
  `}
`


const ScrollerArrow = styled.div<{ left?: boolean, active: boolean, stackMobile: boolean }>`
  position: absolute;
  z-index: 100;
  ${props => props.left ? `
    left: 0px;
    transform: rotateZ(180deg);
  ` : `
    right: 0px;
  `}
  top: 0;
  bottom: 0;
  width: 30px;
  padding: 0 20px;
  @media only screen and (max-width: 743px) {
    ${props => props.stackMobile && `display: none;`}
  }
  @media only screen and (min-width: 744px) {
    padding: 0 30px;
  }
  background-image:url('/images/scrollerArrow.svg');
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: opacity 0.25s ease-in-out;
  opacity: ${props => props.active ? 1 : 0};
  cursor: ${props => props.active ? 'pointer' : 'inherit'};
`


const Scroller = styled.div<{ stackMobile?: boolean, disableScroll?: boolean }>`
  position: relative;
  scroll-behavior: smooth;
  ${props => !props.stackMobile && `
    width: 100vw;
    ${!props.disableScroll && `
      overflow-x: scroll;
      overflow-y: hidden;    
    `}
  `}
  @media only screen and (min-width: 744px) {
    width: 100vw;
    ${props => !props.disableScroll && `
      overflow-x: scroll;
      overflow-y: hidden;    
    `}
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display:none;
    width: 0;
    height: 0;
  }
`

const Strip = styled.div<{ 
  gridColumnWidth?: number, 
  stackMobile?: boolean,
}>`
  position: relative;
  display: grid;
  grid-template-columns: 
    ${props => props.stackMobile 
      ? `1fr` 
      : `repeat(99, ${props.gridColumnWidth ?? 0}px)`};
  grid-column-gap: 20px;
  grid-row-gap: ${props => props.stackMobile ? 75 : 25}px;

  /* stupid hack, overflow doesn't have padding on the right */
  > *:last-of-type {
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -20px;
      width: 20px;
      @media only screen and (min-width: 744px) {
        right: -30px;
        width: 30px;
      }
      height: 1px;
    }
  }

  @media only screen and (min-width: 744px) {
    grid-template-columns: repeat(99, ${props => props.gridColumnWidth}px);
    grid-column-gap: 30px;
    padding: 0px 30px;
  }
`


export const ScrollStripGridItem = styled(props => <GridItem {...props} />)<{ 
  gridColumnWidth: number, 
  columns: number, 
  mobileColumns: number,
}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: ${props => (props.mobileColumns * props.gridColumnWidth) + ((props.mobileColumns - 1) * 20)}px;
  @media only screen and (min-width: 744px) {
    width: ${props => (props.columns * props.gridColumnWidth) + ((props.columns - 1) * 30)}px;
  }
  @media only screen and (min-width: 1024px) {
    width: ${props => (props.columns * props.gridColumnWidth) + ((props.columns - 1) * 30)}px;
  }
  
  /* override "fade up" animation, it messes up the scroller */
  img, video {
    transform: none !important;
  }
      
`


export default GridItemScrollStrip