import React from "react"
import styled from 'styled-components'
import { type } from '@theme'

// Component

const InputSwitch = ({
  name,
  onChange,
  required,
  checked,
  foregroundColor,
  backgroundColor,
  onLabel,
  offLabel,
  className,
}: InputSwitchProps): React.ReactElement => (
  <Wrapper {...{ className, foregroundColor }}>
  	<Label {...{checked, foregroundColor, backgroundColor}} >{onLabel}</Label>
  	<Label {...{foregroundColor, backgroundColor}} checked={!checked} >{offLabel}</Label>
  	<Input
  	  type="checkbox"
  	  {... { name, onChange, checked }}
  	  required={!!required}
  	/>
    <Switch {...{ checked, foregroundColor}} />
  </Wrapper>
)


// Styling

const Wrapper = styled.label<{ foregroundColor: string }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  cursor: pointer;
  width: 150px;
  border: 1px solid ${props => props.foregroundColor};
  border-radius: 500px;
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

const Label = styled.span<{ checked: boolean, foregroundColor: string, backgroundColor: string }>`
  position: relative;
  z-index: 1;
  color: ${props => props.checked ? props.backgroundColor : props.foregroundColor};
  width: 50%;
  border-radius: 500px;
  letter-spacing: 0;  
  text-align: center;
  line-height: 1em;
  transition: color 0.15s ease-in-out;
  padding: 7px 0;
  ${type.micro}
`

const Switch = styled.div<{ checked: boolean, foregroundColor: string }>`
  position: absolute;
  left: ${props => props.checked ? '0' : '50%'};
  z-index: 0;
  width: 50%;
  height: 100%;
  background-color: ${props => props.foregroundColor};
  border-radius: 500px;
  transition: left 0.15s ease-in-out;
`


// Typing

type InputSwitchProps = {
  name: string
  onChange: () => void
  required?: boolean
  checked?: boolean
  labelColor?: string
  foregroundColor?: string
  backgroundColor?: string
  onLabel: string,
  offLabel: string,
  className?: string
}


export default InputSwitch
