import React from "react"
import styled from 'styled-components'
import { GridItemCaption, GridItemImage, GridItemVideo, GridItemScrollStrip, GridItemFocusStrip } from '@components/molecules'
import { GridItemProps, GridItemCaptionProps, GridItemVideoProps, GridItemImageProps, GridItemStripProps} from '@types'


const GridItem = (
  props: GridItemProps | GridItemStripProps
): React.ReactElement => {
  const defaultLayout = {
    fullBleed: false,
    layout: {
      columns: 12,
      mobileColumns: 6,
      columnStart: 1,
      mobileColumnStart: 1,
    }
  }
  if( props._type === 'gridItemStrip' && props.stripStyle === 'scroll') {
    return (
      <GridItemScrollStrip {...props as GridItemStripProps} insideGrid />
    )
  }
  if( props._type === 'gridItemStrip' && props.stripStyle === 'focus') {
    return (
      <GridItemFocusStrip {...props as GridItemStripProps} insideGrid />
    )
  }
  if( props._type === 'gridItem' && props.gridItemTypeSwitch?.gridItemType === 'caption' ) {
    return <GridItemCaption {...props as GridItemCaptionProps} layout={props.layout ?? defaultLayout} />
  }
  if( props._type === 'gridItem' && props.gridItemTypeSwitch?.gridItemType === 'image' ) {
    return <GridItemImage {...props as GridItemImageProps} layout={props.layout ?? defaultLayout} />
  }
  if( props._type === 'gridItem' && props.gridItemTypeSwitch?.gridItemType === 'video' ) {
    return <GridItemVideo {...props as GridItemVideoProps} layout={props.layout ?? defaultLayout} />
  }
  return <></>
}



export const GridItemWrapper = styled.div<{ 
  fullBleed?: boolean,
  mobileColumns?: number, 
  mobileColumnStart?: number,
  columns?: number, 
  columnStart?: number,
  adjustMargin?: number, 
  layer?: 'normal' | 'low' | 'high'
}>`
  position: relative;
  z-index: ${props => (props.layer === 'high') ? 51 : (props.layer === 'low') ? 49 : 50};
  grid-column-start: ${props => props.mobileColumnStart || 'auto'};
  grid-column-end: span ${props => props.mobileColumns || 6};

  @media only screen and (min-width: 744px) {
    ${props => props.adjustMargin && `margin-top: ${props.adjustMargin/2}px;`}
    grid-column-start: ${props => props.columnStart || 'auto'};
    grid-column-end: span ${props => props.columns || 12};
  }
  
  @media only screen and (min-width: 1024px) {
    ${props => props.adjustMargin && `margin-top: ${props.adjustMargin}px;`}
  }
  
  align-self: start;
  
  ${props => props.fullBleed && `
    width: 100vw;
    grid-column-start: 1 !important;
    grid-column-end: span 6;
    margin-left: -20px;
    @media only screen and (min-width: 744px) {
      margin-left: -30px;
      grid-column-end: span 12;
    }
    @media only screen and (min-width: 1024px) {
      margin-left: -45px;
    }
    @media only screen and (min-width: 1200px) {
      margin-left: -60px;
    }
  `}
  &:hover .moreBug {
    opacity: 1;
    transform: scale(1);    
  }
`


export const ChildrenWrapper = styled.div`
  margin-top: 1em;
`



// todo: move these
export const Description = styled.h3`
  color: rgb(106, 106, 106);
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

export const ProjectTitle = styled.h3`
  white-space: nowrap;
  display: inline-block;
  padding-right: 15px;
`




export default GridItem