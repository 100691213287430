import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'


// TODO import { imgixPath } from '@utils'
const imgixPath = (input: string) => input.replace('cdn.sanity.io/images/zchnowat/production', 'franklyn.imgix.net')


const Picture = ({
  objectFit = 'contain',
  className = '',
  src,
  mobileSrc,
  dimensions,
  alt,
  children,
  ...props
}: ImageProps): React.ReactElement => {
  if(!src) return <></>
  // Set CDN src. 
  const mobileCdnSrc = (!!mobileSrc) ? imgixPath(mobileSrc) : undefined
  const cdnSrc = imgixPath(src)

  return (
      <picture>
        {!!children && children}
        {!children && (
          <>
            {mobileCdnSrc && (
              <source type="image/webp" media="(max-width: 743px)"  srcSet={`${mobileCdnSrc}?fm=webp&w=840`} />
            )}
            {!mobileSrc && (
              <source type="image/webp" media="(max-width: 743px)"  srcSet={`${cdnSrc}?fm=webp&w=840`} />
            )}
            <source type="image/webp" media="(min-width: 1280px)" srcSet={`${cdnSrc}?fm=webp&w=1200, ${cdnSrc}?fm=webp&w=2400 2x`} />
            <source type="image/webp" media="(min-width: 1024px)" srcSet={`${cdnSrc}?fm=webp&w=1024, ${cdnSrc}?fm=webp&w=2048 2x`} />
            <source type="image/webp" media="(min-width: 744px)"  srcSet={`${cdnSrc}?fm=webp&w=744, ${cdnSrc}?fm=webp&w=1488 2x`} />
          </>
        )}

        <StyledImage
          src={cdnSrc}
          alt={alt}
          {...{ objectFit }}
          {...dimensions}
          className={`loadable ${className}`}
          {...props}
        />
      </picture>
  )
}



// Styling

const StyledImage = styled.img<{
  objectFit: 'contain' | 'cover',
  aspectRatio?: number,
}>`
  display: block;
  width: 100%;
  ${props => props.aspectRatio && `height: calc(100% / ${props.aspectRatio});`}
  margin: 0;
  padding: 0;
  border: 0;
  object-fit: ${props => props.objectFit};
  border-radius: 15px;
`



// Typing

type ImageProps = {
  src: string
  mobileSrc?: string
  objectFit?: 'contain' | 'cover'
  dimensions?: {
    width: number
    height: number
    aspectRatio: number
  }
  className?: string
  alt?: string
  children?: React.ReactNode
}



export default Picture
