import React from "react"
import styled from 'styled-components'
import { BlockRenderer, LinkMask } from '@components/atoms'
import { GridItemCaptionProps } from '@types'
import { GridItemWrapper, ChildrenWrapper } from './GridItem'


const GridItemCaption = ({
  link,
  post,
  layout: {
    fullBleed,
    layout,
  },
  children,
  gridItemTypeSwitch,
  className,
}: GridItemCaptionProps & { className?: string }): React.ReactElement => {
  if( !gridItemTypeSwitch.gridItemCaption ) return <></>  
  const {
    gridItemCaption: {
      _rawCaption,
    },
  } = gridItemTypeSwitch
  return (
    <GridItemWrapper {...{ fullBleed }} {...layout} className={`grid_item_caption ${className}`} >
      <LinkMask {...{link}} slug={post?.slug} />
      <ItemCaption>
        <BlockRenderer>{_rawCaption}</BlockRenderer> 
      </ItemCaption>
      {children && (
        <ChildrenWrapper>
          {children}
        </ChildrenWrapper>
      )}
    </GridItemWrapper>
  )
}




const ItemCaption = styled.div`
  position: relative;
`




export default GridItemCaption